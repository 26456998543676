<template>
  <app-form-view
    multipart
    app="contact"
    model="personaldocument"
    api-url="contact/personal-doc/"
    :title="$t('menu.MemorandumOfAssociation')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveFields"
    :prepare-data="prepareData"
    v-model="formData"
    @input="loadDocuments"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" md="6">
          <v-row
            ><v-col cols="12" lg="12">
              <app-input
                name="name"
                :label="$t('fields.documentName')"
                :view="view"
                v-model="formData.document_name"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="contact"
                type="chips-server"
                rules="required"
                :label="$t('fields.contact')"
                :view="view"
                :binds="{
                  apiUrl: 'contact/contact/?contact_type=company',
                  chip: {
                    color: 'secondary',
                    icon: 'mdi-account-circle-outline',
                    value: 'name',
                    router: contactRouter
                  }
                }"
                :filterObject="filterObject"
                v-model="formData.contact_ids"
              />

              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_contact_ids"
              />
            </v-col>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="type"
                rules="required"
                :label="$t('fields.type')"
                :view="view"
                value="company"
                disabled
              >
                <template v-slot:view-value="{ value }">
                  {{ $t(`label.${value}`) }}
                </template>
              </app-input>

              <app-input
                v-if="isSentToApprove"
                name="approveType"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_type"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="documentTemplate"
                rules="required"
                :label="$t('fields.documentTemplate')"
                :view="view"
                value="หนังสือบริคณห์สนธิ"
                disabled
              />

              <app-input
                v-if="isSentToApprove"
                name="approveDocumentTemplate"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_template_id"
              />
            </v-col>
            <template v-if="formData.document_template_id">
              <v-col
                v-for="field in documentTemplateFields"
                cols="12"
                md="6"
                class="app-input--approve-wrapper"
                :key="field.field"
              >
                <app-input
                  :rules="{
                    required: true,
                    numeric: field.field_type === 'number'
                  }"
                  :name="field.field"
                  :type="field.field_type"
                  :label="field.name"
                  :items="fieldSelectionItems(field)"
                  :view="view"
                  v-model="formData.fields[field.field]"
                />

                <app-input
                  v-if="isSentToApprove"
                  type="switch"
                  class="approve"
                  :name="`approve-${field.name}`"
                  :label="$t('fields.approve')"
                  v-model="formData[`approve_${field.field}`]"
                />
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="customer_name"
                type="select-server"
                rules="required"
                :label="$t('fields.customerCompany')"
                :view="view"
                :binds="{
                  apiUrl: 'customer-company/customer_company/?active=true'
                }"
                v-model="formData.customer_company_id"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveCustomerCompany"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_customer_company"
              />
            </v-col>
          </v-row>
          <v-row v-if="!view">
            <v-col cols="12" lg="6">
              <app-input
                name="documents"
                type="file"
                rules="required"
                :label="$t('fields.document')"
                :view="view"
                :binds="{ multiple: true, chips: true }"
                v-model="formData.documents"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <app-input
                name="active"
                type="switch"
                :label="$t('fields.active')"
                :view="view"
                v-model="formData.active"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <app-preview-files
            :file-ready="documentReady || mode === 'create'"
            :files="formData.documents || []"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <v-card flat>
            <v-card-text>
              <app-table
                server-side
                ref="table"
                app="special_document"
                model="contributordocument"
                :hide-edit="mode !== 'edit'"
                :hide-delete="mode !== 'edit'"
                :serverItems="items"
                :clientItems="items"
                :headers="headers"
                :loading="loading"
                :server-items-length="serverItemsLength"
                v-model="selected"
                v-bind="binds"
                @delete="onDeleteItem"
                @server="getItems"
                @client="getClientItems"
                @edit="onEdit"
                v-if="mode !== 'create'"
              >
                <template v-slot:top>
                  <v-toolbar flat color="primary" dark
                    ><v-toolbar-title>{{
                      $t('label.cocreator')
                    }}</v-toolbar-title>

                    <v-divider class="mx-4"></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="1000px">
                      <template v-slot:activator="{ on, attrs }">
                        <slot name="actions">
                          <slot name="prefix-actions" />
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            v-show="mode === 'edit'"
                          >
                            New Item
                          </v-btn>
                          <v-btn
                            v-show="selected && mode === 'edit'"
                            color="error"
                            @click="onDelete(selected)"
                          >
                            {{ $t('btn.delete') }}
                          </v-btn>

                          <slot name="suffix-actions" />
                        </slot>
                      </template>
                      <v-card>
                        <v-card-text>
                          <app-form @submit="save" hide-save hide-cancel>
                            <template>
                              <v-container>
                                <v-row>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.seq"
                                      name="seq"
                                      :label="$t('fields.no')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.contact_id"
                                      name="contact_id"
                                      type="select-server"
                                      :label="$t('fields.contact')"
                                      :view="view"
                                      :binds="{
                                        apiUrl:
                                          'contact/contact/?contact_type=individual'
                                      }"
                                      :filterObject="filterObject"
                                      @input="getDataDetails"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.prefix_id"
                                      name="prefix_id"
                                      type="select-server"
                                      :label="$t('fields.prefix')"
                                      :view="view"
                                      :binds="{
                                        apiUrl:
                                          'contact/contact-prefix/?contact_type=individual'
                                      }"
                                      ref="prefix"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.first_name"
                                      name="first_name"
                                      :label="$t('fields.name')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.last_name"
                                      name="last_name"
                                      :label="$t('fields.lastName')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.card_type"
                                      name="card_type"
                                      :label="$t('fields.cardType')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.id_card"
                                      name="id_card"
                                      :label="$t('fields.id')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.age"
                                      name="age"
                                      :label="$t('fields.age')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.phone_number"
                                      name="phoneNumber"
                                      :label="$t('fields.phoneNumber')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.address"
                                      name="address"
                                      :label="$t('fields.address')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.occupation"
                                      name="occupation"
                                      :label="$t('fields.occupation')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                  <v-col cols="4" sm="6" md="6">
                                    <app-input
                                      v-model="editedItem.total_stock"
                                      name="total_stock"
                                      :label="$t('fields.numberShares')"
                                      :view="view"
                                      rules="required"
                                    ></app-input>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </template>
                            <template v-slot:actions>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="close"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn color="blue darken-1" text type="submit">
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </template>
                          </app-form>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
              </app-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <slot :view="view" />
    </template>
  </app-form-view>
</template>

<script>
import AppForm from '@components/AppForm'
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput'
import AppPreviewFiles from '@components/AppPreviewFiles'
import AppTable from '@components/AppTable.vue'
import { getFile } from '@utils/function'

export default {
  name: 'MemorandumOfAssociation-form',
  components: {
    AppInput,
    AppFormView,
    AppPreviewFiles,
    AppTable,
    AppForm
  },
  props: {
    hideEdit: {
      type: Boolean,
      default: true
    },
    hideDelete: {
      type: Boolean,
      default: false
    },
    binds: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    headers() {
      return [
        { text: this.$t('fields.no'), value: 'seq' },
        { text: this.$t('fields.prefix_id'), value: 'prefix_id.name' },
        { text: this.$t('fields.name'), value: 'first_name' },
        { text: this.$t('fields.lastname'), value: 'last_name' },
        { text: this.$t('fields.cardType'), value: 'card_type' },
        { text: this.$t('fields.id'), value: 'id_card' },
        { text: this.$t('fields.age'), value: 'age' },
        { text: this.$t('fields.phoneNumber'), value: 'phone_number' },
        { text: this.$t('fields.occupation'), value: 'occupation' },
        { text: this.$t('fields.address'), value: 'address' },
        { text: this.$t('fields.numberShares'), value: 'total_stock' },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    documentTemplateFields() {
      const fields = [...this.formData.document_template_id.fields]
      fields.sort((a, b) => {
        if (a.code < b.code) return -1
        if (a.code > b.code) return 1
        return 0
      })
      return fields
    },
    approveFields() {
      return this.formData.document_template_id
        ? [...this.formData.document_template_id.fields]
            .map((field) => 'approve_' + field.field)
            .concat([
              'approve_contact_ids',
              'approve_type',
              'approve_document_template_id',
              'approve_document_name',
              'approve_customer_company'
            ])
        : []
    }
  },

  data() {
    return {
      documentReady: false,
      formData: {
        state: 'draft',
        fields: {}
      },
      search: '',
      items: [],
      selected: [],
      savedItem: {},
      serverItemsLength: 0,
      loading: false,
      editedItem: {
        personal_id: null,
        card_type: null,
        phone_number: null,
        occupation: null
      },
      dialog: false,
      dialogDelete: false,
      apiMethod: 'post',
      editId: null
    }
  },
  watch: {
    'formData.document_name': function (value) {
      if (this.$route.name === 'MemorandumOfAssociationEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'MemorandumOfAssociationEdit',
          text: value ?? 'N/A',
          to: {
            name: 'MemorandumOfAssociationEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    contactRouter(item) {
      return {
        name: 'contactEdit',
        params: { id: item.id }
      }
    },
    async loadDocuments() {
      this.formData.documents = await Promise.all(
        this.formData.documents.map((document) => getFile(document))
      )
      this.documentReady = true
    },
    fieldSelectionItems(field) {
      if (field.field_type !== 'select') return []
      return field.selection_members.map((value) => ({
        ...value,
        value: value.label
      }))
    },
    filterObject(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.national_id_no
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    prepareData(data) {
      data.contact_ids = data.contact_ids.map((value) => value.id)
      data.document_template_id = data.document_template_id.id
      data.customer_company_id = data.customer_company_id.id
      data.type = 'company'
      return data
    },
    onEdit(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialog = true
    },
    save() {
      this.loading = true

      if (
        this.editedItem.contact_id !== null &&
        this.editedItem.contact_id !== undefined
      ) {
        this.savedItem.contact_id = this.editedItem.contact_id.id
      }

      this.savedItem.personal_id = this.$route.params.id

      if (
        this.editedItem.first_name !== null &&
        this.editedItem.first_name !== undefined
      ) {
        this.savedItem.first_name = this.editedItem.first_name
      }
      if (
        this.editedItem.last_name !== null &&
        this.editedItem.last_name !== undefined
      ) {
        this.savedItem.last_name = this.editedItem.last_name
      }
      if (this.editedItem.seq !== null && this.editedItem.seq !== undefined) {
        this.savedItem.seq = this.editedItem.seq
      }
      if (
        this.editedItem.card_type !== null &&
        this.editedItem.card_type !== undefined
      ) {
        this.savedItem.card_type = this.editedItem.card_type
      }
      if (
        this.editedItem.id_card !== null &&
        this.editedItem.id_card !== undefined
      ) {
        this.savedItem.id_card = this.editedItem.id_card
      }
      if (this.editedItem.age !== null && this.editedItem.age !== undefined) {
        this.savedItem.age = this.editedItem.age
      }
      if (
        this.editedItem.phone_number !== null &&
        this.editedItem.phone_number !== undefined
      ) {
        this.savedItem.phone_number = this.editedItem.phone_number
      }
      if (
        this.editedItem.address !== null &&
        this.editedItem.address !== undefined
      ) {
        this.savedItem.address = this.editedItem.address
      }
      if (
        this.editedItem.occupation !== null &&
        this.editedItem.occupation !== undefined
      ) {
        this.savedItem.occupation = this.editedItem.occupation
      }
      if (
        this.editedItem.total_stock !== null &&
        this.editedItem.total_stock !== undefined
      ) {
        this.savedItem.total_stock = this.editedItem.total_stock
      }
      if (
        this.editedItem.prefix_id !== null &&
        this.editedItem.prefix_id !== undefined
      ) {
        this.savedItem.prefix_id = this.editedItem.prefix_id.id
      }

      const data = this.savedItem
      var itemID = ''
      if (this.editId !== null) {
        itemID = this.editId + '/'
      }

      this.$api({
        method: this.apiMethod,
        url: `special-doc/contributor-doc/` + itemID,
        data
      })
      this.loading = false
      this.apiMethod = 'post'
      this.close()
      this.getItems()
    },
    getItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `special-doc/contributor-doc?personal_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.items = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `special-doc/contributor-doc/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    getDataDetails() {
      if (
        this.editedItem.contact_id !== null &&
        this.editedItem.contact_id !== undefined
      ) {
        this.editedItem.prefix_id = this.editedItem.contact_id.prefix_id
        this.editedItem.first_name = this.editedItem.contact_id.first_name
        this.editedItem.last_name = this.editedItem.contact_id.last_name
        this.editedItem.id_card = this.editedItem.contact_id.national_id_no
        this.$refs.prefix.setDefaultServerItems(this.editedItem.prefix_id)
      }
    },
    getDocData() {
      this.$api({
        method: 'get',
        url: `doc-template/document/?search=บริคณห์สนธิ`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.formData.document_template_id = data.results[0]
        this.loading = false
      })
    }
  },
  created() {
    this.getDocData()
    if (this.$route.name === 'MemorandumOfAssociationCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'MemorandumOfAssociationCreate'
      })
    }
  }
}
</script>
